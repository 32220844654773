import React from 'react'

export const InfoText = ({border = false, header, text, children}) => {
    return (
        <div className={`${border && 'partial-border'} rounded margin-small info-text bg-lighten`}>
            { header ? header : <></> }
            { text ? <p className="center">{text}</p> : <></> }
            { children }
        </div>
    )
}