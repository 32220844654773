import React, { useEffect, useState , useCallback} from 'react';
import { Route } from 'react-router-dom';
import { photos as ImageGalleryPhotos } from './image-gallery-sources';
import { photos as PhotoGalleryPhotos } from './photo-gallery-sources'
import 'react-image-gallery/styles/scss/image-gallery.scss';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import ReactPhotoGallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from "react-images";

export const Gallery = () => {
    const [viewportWidth, setViewportWidth] = useState(0);
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
  
    const openLightbox = useCallback((event, { photo, index }) => {
      setCurrentImage(index);
      setViewerIsOpen(true);
    }, []);
  
    const closeLightbox = () => {
      setCurrentImage(0);
      setViewerIsOpen(false);
    };

    useEffect(() => {
        setViewportWidth(Math.max(document.documentElement.clientWidth, window.innerWidth || 0));
    }, [])

    return (
        <Route exact path="/galleria">
            <div className="header-page-content">
                <h2 className="">galleria</h2>
            </div>
            <div className="col-12 col-s-12">
                {viewportWidth < 600 ? (
                    <ImageGallery lazyLoad={true} originalClass="gallery-image" items={ImageGalleryPhotos}/>
                ) : (
                    <div>
                    <ReactPhotoGallery photos={PhotoGalleryPhotos} onClick={openLightbox}/>
                    <ModalGateway>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                                <Carousel
                                currentIndex={currentImage}
                                //styles={customStyles}
                                views={PhotoGalleryPhotos.map(x => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title
                                }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                    </div>
                )}
            </div>
        </Route>
    )
}