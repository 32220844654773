import React from 'react';
import { Route } from 'react-router-dom';
import { InfoText } from './InfoText.js';
import { Link as RouterLink } from 'react-router-dom';

export const ServicesInformation = () => {
    return (
        <Route exact path="/palvelut">
            <div className="header-page-content">
                <h2 className="">palvelut</h2>
            </div>
            <div className="container-page-content row">
                <div className="col-12 col-s-12">
                    <InfoText
                        border={false}
                        text="Suunnittelemme yhdessä asiakkaan kanssa tarpeelliset apuvälineet asumisen helpottamiseksi"
                        header={ <h3 className="center info-text-sub-header">Vanhusten ja vammaisten apuvälineet</h3> }
                        />
                    <InfoText
                        border={false}
                        header={ <h3 className="center info-text-sub-header">Kiinteistöjen huoltopalvelut</h3> }
                        >
                        <div>
                            <p className="center">
                                Ympärivuotinen ulkopuhtaanpito ja viheralueiden hoito sekä lumityö
                            </p>
                        </div>
                        <div>
                            <p className="center">
                            Kohderyhmänä asukkaat, asunto-osakeyhtiöt, rivitaloyhtiöt, omakotitalot, kesämökit, yritykset sekä liikekiinteistöt
                            </p>
                        </div>
                    </InfoText>
                    
                    <InfoText border={false} header={<h3 className="center info-text-sub-header">Rakentaminen ja remontointi</h3>}>
                        <div>
                            <p className="center">
                                Kotien muutostyöt
                            </p>
                        </div>
                        <div>
                            <p className="center">
                                Uudisrakennukset talosta talliin
                            </p>
                        </div>
                        <div>
                            <p className="center">
                                Liikehuoneistot, keittiöt, kylpyhuoneet yms…
                            </p>
                        </div>

                    </InfoText>
                </div>
            </div>
            <div className="container-page-content row">
                <div className="col-6 col-s-6">
                    <div className="partial-border rounded margin-small info-text bg-lighten">
                        <h3 className="center info-text-sub-header">
                            Hengetär-ilmanvaihto
                        </h3>
                        <p className="center margin-small">Hoidamme Hengetär -ilmanvaihtolaitteiden valmistuksen, myynnin sekä asennukset</p>
                        <p className="center margin-small">Hengetär ilmanvaihdin poistaa epäpuhdasta sisäilmaa ja tuo tilalle saman määrän raikasta ulkoilmaa</p>
                        <p className="center margin-small">Lämmittää tarvittaessa sisään tulevaa ilmaa termostaattiohjatulla lisälämmittimellä</p>
                        <p className="center margin-small">Hoitaa tuuletuksen automaattisesti, jatkuvasti ja energiatehokkaasti</p>
                        <p className="center margin-small">Vähentää oleellisesti radonpitoisuutta</p>
                        <p className="center margin-small">Lue lisää <a href="http://luxusilma.fi">luxusilma.fi</a></p>
                    </div>
                </div>
                <div className="col-6 col-s-6">
                    <div className="partial-border rounded margin-small info-text bg-lighten oiva">
                        <h3 className="center info-text-sub-header">
                            OIVA-kaapistot
                        </h3>
                        <p className="center margin-small">Suunnittelemme, valmistamme ja myymme teknisiin työpisteisiin laadukkaita kaapistoja ja laatikoita</p>
                        <p className="center margin-small">Mittatilaustyönä, tarpeisiin räätälöitynä valmistetut tekniset työpisteet</p>
                        <p className="center margin-small">Oppilaitoksiin ja teollisuuteen</p>
                        <p className="center margin-small"><span>Katso kuvat </span> 
                        <RouterLink to="/galleria"> 
                            <a href="/galleria">galleriasta</a>
                        </RouterLink>
                        </p>
                    </div>
                </div>
            </div>
            <div className="container-page-content row">
                <div className="col-12 col-s-12">
                    <div className="partial-border rounded margin-small info-text bg-lighten">
                        <h3 className="center info-text-sub-header white decreased-font">
                            Muista hyödyntää kotitalousvähennys!
                        </h3>
                        <p className="center margin-small">Kotona tehdyn huolto- tai remonttityön kustannukset on osittain mahdollista vähentää verotuksessa</p>
                    </div>
                </div>
            </div>
        </Route>
    )
}