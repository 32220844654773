import React, { useState } from 'react';
import { Route } from 'react-router-dom'
import { useFormik } from "formik";
import * as Yup from 'yup';
import axios from 'axios';
import { InfoText } from './InfoText.js'

const bu = 'https://us-central1-pkt-website-7f3c9.cloudfunctions.net'

const validationSchema = Yup.object({
    name: Yup.string().required('Pakollinen kenttä').min(5, 'Vaaditaan vähintään viisi merkkiä'),
    company: Yup.string().min(2, 'Yrityksen nimen tulee olla vähintään kaksi merkkiä pitkä'),
    phone: Yup.number().min(5, 'Vaaditaan vähintään viisi merkkiä').positive('Vaaditaan positiivinen arvo'),
    email: Yup.string().email('Virheellinen sähköpostiosoite').required('Pakollinen kenttä'),
    message: Yup.string().min(3, 'Vaaditaan vähintään kolme merkkiä').required('Pakollinen kenttä')
});

export const ContactInformation = () => {
    const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);
    const [technicalError, setTechnicalError] = useState(false);

    const handleSubmit = async (formik) => {
        try {
            await axios.post(`${bu}/sendMessage`, formik.values);
            setSuccessfullySubmitted(true);
            setTechnicalError(false);
            formik.resetForm()
        } catch (e) {
            formik.setSubmitting(false);
            setTechnicalError(true);
            setSuccessfullySubmitted(false);
        }
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            company: '',
            phone: '',
            email: '',
            message: ''
        },
        validationSchema,
        onSubmit: () => {
            handleSubmit(formik)
        }
        })
    return (
        <Route exact path="/yhteystiedot">
            <div className="header-page-content">
                <h2 className="">yhteystiedot</h2>
            </div>
            <div className="container-page-content row">
                <div className="col-5 col-s-12 container-contact-info">
                    <InfoText
                        border={false}
                        header={<h3 className="center info-text-sub-header">Olli-Pekka Nolvi</h3>}>
                            <p className="center">Tmi Pirkkalan kylätalkkari / KPH-asennus</p>
                            <p className="center">Y-tunnus: 2032745-7</p>
                            <p className="center">Lentoasemantie 278</p>
                            <p className="center">33960 Pirkkala</p>
                            <p className="center">+358 40 168 1588</p>
                            <p className="center email">olli(at)pirkkalankylatalkkari.fi</p>
                    </InfoText>
                </div>
                <div className="col-7 col-s-12">
                    <h3 className="header-padded center">Ota yhteyttä</h3>
                    <form onSubmit={formik.handleSubmit}> 
                    <div className="form-row">
                        <label htmlFor="name">Nimi</label>
                        <input
                            id="name"
                            type="text"
                            maxLength="50"
                            placeholder="Etunimi Sukunimi"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name 
                            ? (<div className="feedback">{formik.errors.name}</div>) 
                            : null
                        }
                    </div>
                    <div className="form-row">
                        <label htmlFor="company">Yritys</label>
                        <input
                            id="company"
                            type="text"
                            maxLength="50"
                            placeholder="Yritys Oy"
                            value={formik.values.company}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.company && formik.errors.company 
                            ? (<div className="feedback">{formik.errors.company}</div>) 
                            : null
                        }
                    </div>

                    <div className="form-row">
                        <label htmlFor="email">Sähköposti</label>
                        <input
                            id="email"
                            type="text"
                            maxLength="50"
                            placeholder="sähköposti@osoite.fi"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email 
                            ? (<div className="feedback">{formik.errors.email}</div>) 
                            : null
                        }
                    </div>
                    <div className="form-row">
                        <label htmlFor="phone">Puhelinnumero</label>
                        <input
                            id="phone"
                            type="number"
                            maxLength="50"
                            placeholder="+358 123 4567"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.phone && formik.errors.phone 
                            ? (<div className="feedback">{formik.errors.phone}</div>) 
                            : null
                        }
                    </div>
                    <div className="form-row">
                        <label htmlFor="message">Viesti</label>
                        <textarea 
                            id="message"
                            type="text"
                            placeholder="Viesti"
                            maxLength="300"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.message && formik.errors.message 
                            ? (<div className="feedback">{formik.errors.message}</div>) 
                            : null
                        }
                    </div>
                    <div className="form-row center">
                        { technicalError && (
                            <p>Viestin lähettämisessä tapahtui tekninen virhe. Yritäthän myöhemmin uudelleen.</p>
                        ) }
                        { successfullySubmitted ? (
                            <p>Kiitos viestistä!</p>
                        ) : (
                            <button className="button-submit btn-animated" type="submit" disabled={formik.isSubmitting}>
                                { !formik.isSubmitting ? 'Lähetä' : 'Lähetetään...'}
                            </button>
                        )}
                    </div>
                </form>
                </div>
            </div>
        </Route>
    )
}