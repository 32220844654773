import React from 'react';
import './App.css';
import { ContactInformation } from './components/ContactInformation';
import { Gallery } from './components/Gallery';
import { ServicesInformation } from './components/ServicesInformation';
import { IndexInformation } from './components/IndexInformation';
import { Link } from './components/Link';
import { Link as RouterLink } from 'react-router-dom';
import { Switch } from 'react-router-dom';

function App() {
  return (
      <div id="app" className="App">
        <div className="container-main">
          <div className="header"></div>
          <div className="text-box">
            <h1>
                <div className="textbox-header">
                    <span className="header-main">PKT</span>
                    <span className="header-sub">Pirkkalan Kylätalkkari</span>
                </div>
            </h1>
        </div>
          <div className="container-links">
                <RouterLink to="/" className="btn">
                    <span>etusivu</span>
                </RouterLink>
                <Link content="palvelut"/>
                <Link content="yhteystiedot"/>
                <Link content="galleria"/>
            </div>
          <div className="container-content">
            <Switch>
                <div>
                    <IndexInformation />
                    <ContactInformation />
                    <ServicesInformation />
                    <Gallery/>
                </div>
            </Switch>
          </div>
        </div>
      </div> 
  );
}

export default App;
