export const photos = [
    {
        src: './gallery_photos/1.jpg',
        width: 1,
        height: 1,
        title: 'Rampit ja tukikaiteet'
    },
    {
        src: './gallery_photos/3.jpg',
        width: 1,
        height: 1,
        title: 'Rampit'
    },
    {
        src: './gallery_photos/4.jpg',
        width: 1,
        height: 1,
        title: 'Käytäväkivetykset'
    },
    {
        src: './gallery_photos/29.jpg',
        width: 1,
        height: 1,
        title: 'Käytäväkivetykset'
    },
    {
        src: './gallery_photos/5.jpg',
        width: 1,
        height: 1,
        title: 'Liikehuoneistot'
    },
    {
        src: './gallery_photos/7.jpg',
        width: 1,
        height: 1,
        title: 'Kylpyhuoneet'
    },
    {
        src: './gallery_photos/8.jpg',
        width: 1,
        height: 1,
        title: 'Saunat'
    },
    {
        src: './gallery_photos/9.jpg',
        width: 1,
        height: 1,
        title: 'Apuvälineet'
    },
    {
        src: './gallery_photos/12.jpg',
        width: 1,
        height: 1,
        title: 'Laiturit'
    },
    {
        src: './gallery_photos/13.jpg',
        width: 1,
        height: 1,
        title: 'Laiturit'
    },
    {
        src: './gallery_photos/14.jpg',
        width: 1,
        height: 1,
        title: 'Väliaidat'
    },
    {
        src: './gallery_photos/15.jpg',
        width: 1,
        height: 1,
        title: 'Sisä- ja ulkokeittiöt'
    },
    {
        src: './gallery_photos/16.jpg',
        width: 1,
        height: 1,
        title: 'Sisä- ja ulkokeittiöt'
    },
    {
        src: './gallery_photos/31.jpg',
        width: 1,
        height: 1,
        title: 'Keittiöt'
    },
    {
        src: './gallery_photos/28.jpg',
        width: 1,
        height: 1,
        title: 'Keittiöt'
    },
    {
        src: './gallery_photos/18.jpg',
        width: .8,
        height: 1,
        title: 'Varastorakennukset'
    },
    {
        src: './gallery_photos/21.jpg',
        width: 1,
        height: 1,
        title: 'Autotallit'
    },
    {
        src: './gallery_photos/22.jpg',
        width: 1,
        height: 1,
        title: 'Hirsityöt'
    },
    {
        src: './gallery_photos/30.jpg',
        width: 1,
        height: 1,
        title: 'Entisöinnit'
    },
    {
        src: './gallery_photos/26.png',
        width: 1,
        height: 1,
        title: 'OIVA-kaapistot'
    },
    {
        src: './gallery_photos/33.jpg',
        width: 1,
        height: 1,
        title: 'OIVA-kaapistot'
    },
    {
        src: './gallery_photos/32.jpg',
        width: 1,
        height: 1,
        title: 'Lehtikompostit'
    },
    {
        src: './gallery_photos/34.jpg',
        width: 1,
        height: 1,
        title: 'Hengetär-ilmanvaihtolaitteet'
    },
    {
        src: './gallery_photos/35.jpg',
        width: 1,
        height: 1,
        title: 'Hengetär-ilmanvaihtolaitteet'
    }
]