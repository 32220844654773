import React from 'react';
import { Route } from 'react-router-dom';
import { InfoText } from './InfoText.js'

export const IndexInformation = () => {
    return (
        <Route exact path="/">
            <div className="header-page-content">
                <h2 className="">yritys</h2>
            </div>
            <div className="container-page-content row">
                <div className="col-8 col-s-12">
                    <h3 className="header-padded center">Pirkkalan kylätalkkari on moniosaava huoltoyritys</h3>
                    <InfoText text="Hoidamme Pirkanmaan talousalueella kaikki kiinteistöjen huollot ja kotityöt"/>
                    <InfoText text="Toteutamme kodin sisäremontoinnit ja piha-alueen rakentamiset"/>
                    <InfoText text="Palvelemme yksityistalouksia, sekä tuemme vanhusten ja vammaisten kotona asumista kunnostamalla asuntoja ja asentamalla apuvälineitä asumisen helpottamiseksi"/>
                </div>
                <div className="col-4 col-s-12 container-img">
                    <img src="./portrait2.jpg" id="index-info-pic" alt="kuva1"/>
                </div>
                <div className="col-12 col-s-12 container-slogan">
                    <div className="col-8 col-s-12">
                        <div>
                            <p className="slogan">”puuhastelua jo vuodesta 2011”</p>
                        </div>
                    </div>
                    <div className="col-2 col-s-6">
                        <div className="">
                            <img className="logo" src="./strongest-aa.png" alt="Strongest AA"/>
                            <p className="logo-text" >Suomen vahvimmat -sertifikaatti 2020 <br/> Pirkkalan kylätalkkari <br/> KPH-asennus</p>

                        </div>
                    </div>
                    <div className="col-2 col-s-6">
                        <div className="">
                            <img className="logo" src="./ahlman_logo_tekstilla.png" alt="Ahlman logo"/>
                            <p className="logo-text" >Pirkkalan Kylätalkkari on Ahlmannin ammatti-instituutin kouluttama</p>
                        </div>
                    </div>
                </div>
            </div>
        </Route>
    )
}